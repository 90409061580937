<template>
  <div v-loading="loading" element-loading-text="数据较多，拼命加载中..." class="ReferenceSY-container">
    <div class="title-container">
      <div>系统基准值设置</div>
    </div>
    <el-form
      label-width="140px"
      :rules="rulesForm"
      ref="ruleForm"
      :model="form"
    >
      <el-row>
        <el-col :span="10">
          <el-form-item label="首响基准值：" prop="firstTime">
            <el-input
              v-model="form.firstTime"
              class="common-input-s"
            ></el-input> </el-form-item
        ></el-col>
        <el-col :span="10">
          <el-form-item label="平响基准值：" prop="avgTime">
            <el-input
              v-model="form.avgTime"
              class="common-input-s"
            ></el-input> </el-form-item
        ></el-col>
        <el-col :span="10">
          <el-form-item label="问答比：" prop="qaRate">
            <el-input
              v-model="form.qaRate"
              class="common-input-100"
            ></el-input> </el-form-item
        ></el-col>
        <el-col :span="10">
          <el-form-item label="邀评率：" prop="replyRate">
            <el-input
              v-model="form.replyRate"
              class="common-input-100"
            ></el-input> </el-form-item
        ></el-col>
        <el-col :span="10">
          <el-form-item label="满意度：" prop="satisfaction">
            <el-input
              v-model="form.satisfaction"
              class="common-screen-input_85"
            ></el-input> </el-form-item
        ></el-col>
        <el-col :span="10">
          <el-form-item label="工单错误率：" prop="orderErrorRate">
            <el-input
              v-model="form.orderErrorRate"
              class="common-input-100"
            ></el-input> </el-form-item
        ></el-col>
        <el-col :span="10">
          <el-form-item label="	质检错误率：" prop="qualityErrorRate">
            <el-input
              v-model="form.qualityErrorRate"
              class="common-input-100"
            ></el-input> </el-form-item
        ></el-col>
        <el-col :span="10">
          <el-form-item label="服务高压线：" prop="serviceHighVoltage">
            <el-input
              v-model="form.serviceHighVoltage"
              class="common-screen-input_85"
            ></el-input> </el-form-item
        ></el-col>
        <el-col :span="10">
          <el-form-item label="遗漏客诉：" prop="missFeedback">
            <el-input
              v-model="form.missFeedback"
              class="common-screen-input_85"
            ></el-input> </el-form-item
        ></el-col>
        <el-col :span="10">
          <el-form-item label="散客满意度：" prop="satisfactionDifference">
            <el-input
              v-model="form.satisfactionDifference"
              class="common-screen-input_85"
            ></el-input> </el-form-item
        ></el-col>
        <el-col :span="10">
          <el-form-item label="三分钟回复率" prop="threeMinuteResponseRate">
            <el-input
              v-model="form.threeMinuteResponseRate"
              class="common-input-100"
            ></el-input> </el-form-item
        ></el-col>
        <el-col :span="10">
          <el-form-item label="五分钟回复率" prop="fiveMinuteResponseRate">
            <el-input
              v-model="form.fiveMinuteResponseRate"
              class="common-input-100"
            ></el-input> </el-form-item
        ></el-col>
        <el-col :span="10">
          <el-form-item label="微信提醒预警类型：" prop="">
            <el-select v-model="isAssessment" placeholder="请选择" class="common-screen-input_85">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
            <el-tooltip class="item" effect="dark" content="超时预警为单条消息超时
            店铺服务均值预警为店铺子账号当天平均值超时" placement="bottom">
            <img
              src="../../assets/images/question.png"
              class="tit-img"
            />
            </el-tooltip>
            </el-form-item
        ></el-col>
        <el-col class="btn" :span="18">
          <el-button
            class="common-screen-btn"
            type="primary"
            @click="() => setReference('ruleForm')"
            >确 定</el-button
          >
          <el-button
            class="common-screen-btn cancelbtn"
            @click="() => getReference()"
            >取 消</el-button
          >
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>
<script>
// import Breadcrumb from "../../components/common/breadcrumb.vue";
import { getReference, setReference, wxWarn,readwxWarn } from "../../service/dataInfo.js";
import { decimalFour, decimalNull,Config } from "../../utils/index.js";
export default {
  components: {
    // Breadcrumb
  },
  data() {
    return {
      loading: false,
      score: "", // 质检分数
      form: {},
      rulesForm: {
        // 校验规则
        // nickName: [{ required: true, message: "请输入名称", trigger: "change" }],
        firstTime: [{ validator: decimalNull, trigger: "change" }],
        avgTime: [{ validator: decimalNull, trigger: "change" }],
        qaRate: [{ validator: decimalFour, trigger: "change" }],
        replyRate: [{ validator: decimalFour, trigger: "change" }],
        satisfaction: [{ validator: decimalFour, trigger: "change" }],
        orderErrorRate: [{ validator: decimalFour, trigger: "change" }],
        qualityErrorRate: [{ validator: decimalFour, trigger: "change" }],
        serviceHighVoltage: [{ validator: decimalFour, trigger: "change" }],
        missFeedback: [{ validator: decimalFour, trigger: "change" }],
        satisfactionDifference: [{ validator: decimalFour, trigger: "change" }],
        threeMinuteResponseRate: [{ validator: decimalFour, trigger: "change" }],
        fiveMinuteResponseRate: [{ validator: decimalFour, trigger: "change" }],
      },
      isAssessment: "0",
      options: [
        {value: "0",
          label: '超时预警'},
        {value: "1",
          label: '店铺服务均值预警'},

      ],
      rolesCompany: this.$sto.get(Config.constants.userInfo).company_id, // 所属公司
    };
  },
  created() {
    this.getReference();
    this.getwxWarn()
  },
  methods: {
    async getReference() {
      // 获取质检分数
      this.loading = true
      let resData = (await getReference()).data;
      this.loading = false
      let newArr = [
        "qaRate",
        "replyRate",
        "orderErrorRate",
        "qualityErrorRate",
        "threeMinuteResponseRate",
        "fiveMinuteResponseRate",
      ];
      newArr.map((item) => {
        if (resData[item]) {
          resData[item] = resData[item] * 100;
        }
      });
      this.form = {
        id: resData.id || "",
        firstTime: resData.firstTime || "",
        avgTime: resData.avgTime || "",
        qaRate: resData.qaRate || "",
        replyRate: resData.replyRate || "",
        satisfaction: resData.satisfaction || "",
        orderErrorRate: resData.orderErrorRate || "",
        qualityErrorRate: resData.qualityErrorRate || "",
        serviceHighVoltage: resData.serviceHighVoltage || "",
        missFeedback: resData.missFeedback || "",
        satisfactionDifference: resData.satisfactionDifference || "",
        threeMinuteResponseRate: resData.threeMinuteResponseRate || "",
        fiveMinuteResponseRate: resData.fiveMinuteResponseRate || "",
      };
      resData;
    },
    setReference(formName) {
      // 设置基准值
      let form = { ...this.form };
      let newArr = [
        "qaRate",
        "replyRate",
        "orderErrorRate",
        "qualityErrorRate",
        "threeMinuteResponseRate",
        "fiveMinuteResponseRate",
      ];
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          newArr.map((item) => {
            if (form[item]) {
              form[item] = form[item] / 100;
            }
          });
          await setReference(form);
          await wxWarn({companyId: this.rolesCompany,isAssessment: this.isAssessment}) // 微信提醒预警类型接口
          this.getReference();
          this.getwxWarn()
          this.$message.success("设置成功");
        } else {
          return false;
        }
      });
    },
    async getwxWarn() {
      const { data } = await readwxWarn({companyId: this.rolesCompany})
      this.isAssessment = data
    },
  }
};
</script>
<style lang="less" scoped>
.ReferenceSY-container {
  text-align: left;
  .set-title {
    margin: 20px 0;
  }
  .setTip {
    display: inline-block;
    line-height: 32px;
    font-size: 14;
    color: #999;
    margin-left: 8px;
  }
  .btn {
    text-align: center;
    margin-top: 20px;
  }
  .cancelbtn {
    margin-left: 50px;
  }
  .tit-img{
    cursor: pointer;
    margin: 0 12px;
    vertical-align: middle;
  }
  .el-form {
    margin-top: 16px;
    .el-row{
      padding-left: 150px;
    }
  }
}
</style>
